import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='./avnt_logo_white.webp' className="App-logo" alt="logo" />
        <h1>
          COMING SOON
        </h1>
      </header>
    </div>
  );
}

export default App;
